<template>
  <div>
    <b-row class="pb-4">
      <b-col cols="6">
        <h2>{{ $t('orders.words.cars') }}</h2>
      </b-col>
      <b-col cols="6" class="d-flex flex-row-reverse">
        <b-button @click="addNewCar">
          {{ $t('orders.texts.addNewCar') }}
        </b-button>
        <b-button class="mr-2" @click="backHistory">
          {{ $t('orders.words.goBack') }}
        </b-button>
      </b-col>
    </b-row>

    <b-row
      class="pb-4"
    >
      <b-col cols="6" class="d-flex">
        <div class="box-breadcrumb">
          <p class="title">
            {{ $t('orders.words.provider') }}
          </p>
          {{ contract.company_name }}
        </div>
        <div class="box-breadcrumb">
          <p class="title">
            {{ $t('orders.texts.orderNumber') }}
          </p>
          {{ contract.contract_number }}
        </div>
      </b-col>
    </b-row>

    <modal-car-add
      ref="ModalCarAdd"
      :order-id="$route.params.order_id"
      product="RENTAL"
      @carAdded="$emit('newCarAdded')"
    />
  </div>
</template>

<script>
import gql from 'graphql-tag';
import ModalCarAdd from '@components/modals/car-add';

export default {
  name: 'OrderHeader',
  components: {
    ModalCarAdd,
  },
  data() {
    return {
      contract: {},
      companyId: this.$route.params.contract_id
    };
  },
  apollo: {
    contract: {
      query: gql`
        query ContractGet($id: ID!) {
          contract(id: $id) {
            company_name
            contract_number
          }
        }
      `,
      variables() {
        return {
          id: this.companyId,
        };
      },
    },
  },
  methods: {
    addNewCar() {
      this.$refs.ModalCarAdd.$refs.modalCarAdd.show();
    },
    backHistory() {
      this.$router.push(
        `/providers/contract/${this.$route.params.contract_id}`
      );
    },
  },
};
</script>

<style lang="sass">
.box-breadcrumb
  background: rgb(255,255,255)
  border: 1px solid rgb(234, 237, 243)
  border-radius: 4px
  padding: 20px
  color: rgb(62, 63, 66)
  font-size: 24px
  .title
    color: rgb(158, 160, 165)
    font-size: 12px
    text-transform: uppercase
</style>
