var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Layout",
    [
      _c("order-header", { on: { newCarAdded: _vm.handleCarAdded } }),
      _c("order-table", { ref: "orderTable" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }