var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "pb-4" },
        [
          _c("b-col", { attrs: { cols: "6" } }, [
            _c("h2", [_vm._v(_vm._s(_vm.$t("orders.words.cars")))]),
          ]),
          _c(
            "b-col",
            { staticClass: "d-flex flex-row-reverse", attrs: { cols: "6" } },
            [
              _c("b-button", { on: { click: _vm.addNewCar } }, [
                _vm._v(" " + _vm._s(_vm.$t("orders.texts.addNewCar")) + " "),
              ]),
              _c(
                "b-button",
                { staticClass: "mr-2", on: { click: _vm.backHistory } },
                [_vm._v(" " + _vm._s(_vm.$t("orders.words.goBack")) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "pb-4" },
        [
          _c("b-col", { staticClass: "d-flex", attrs: { cols: "6" } }, [
            _c("div", { staticClass: "box-breadcrumb" }, [
              _c("p", { staticClass: "title" }, [
                _vm._v(" " + _vm._s(_vm.$t("orders.words.provider")) + " "),
              ]),
              _vm._v(" " + _vm._s(_vm.contract.company_name) + " "),
            ]),
            _c("div", { staticClass: "box-breadcrumb" }, [
              _c("p", { staticClass: "title" }, [
                _vm._v(" " + _vm._s(_vm.$t("orders.texts.orderNumber")) + " "),
              ]),
              _vm._v(" " + _vm._s(_vm.contract.contract_number) + " "),
            ]),
          ]),
        ],
        1
      ),
      _c("modal-car-add", {
        ref: "ModalCarAdd",
        attrs: { "order-id": _vm.$route.params.order_id, product: "RENTAL" },
        on: {
          carAdded: function ($event) {
            return _vm.$emit("newCarAdded")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }