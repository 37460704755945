<template>
  <div class="prodivers-list">
    <custom-data-table
      ref="orderDataTable"
      :fields="fields"
      :filters="filters"
      :query="cars_get_list"
      :fixed-filters="{ order: $route.params.order_id }"
      :query-variables="{ order: $route.params.order_id }"
      query-node="carsByOrder"
      @vuetable:row-clicked="$_goTo"
    >
      <span slot="license_plate" slot-scope="props">
        <strong>{{ props.rowData.license_plate }}</strong>
      </span>
      <span slot="brand" slot-scope="props">
        <strong>{{ props.rowData.brand ? props.rowData.brand.name : '--' }}</strong>
        / {{ props.rowData.model ? props.rowData.model.name : '--' }}
      </span>
      <span
        slot="assemble_year"
        slot-scope="props"
      >{{ props.rowData.assemble_year }} / {{ props.rowData.model_year }}</span>
      <span
        slot="place"
        slot-scope="props"
      >{{ props.rowData.place ? props.rowData.place.name : '--' }}</span>
      <span slot="status" slot-scope="props">
        <b-badge :variant="formatStatus(props.rowData.status)">{{ props.rowData.status }}</b-badge>
      </span>
      <span slot="edit" slot-scope="props">
        <b-button title="Editar carro" variant="link" @click.stop="$_goToCarEdit(props.rowData.id)">
          <i class="fa fa-edit" />
        </b-button>
      </span>
    </custom-data-table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import CustomDataTable from '@components/CustomDataTable';
import { enumStatus } from '@utils/car';
import CAR_LIST from '@graphql/car/queries/list-by-order.gql';
import { formatStatus } from '@utils/contract';
import { formatGrayPlateOrMercosul } from '@utils/formatters/licensePlate'

export default {
  name: 'ProviderTable',
  components: {
    CustomDataTable,
  },
  data() {
    return {
      cars_get_list: CAR_LIST,
      fields: [
        {
          name: 'license_plate',
          title: this.$t('orders.words.licensePlate'),
          sortField: 'license_plate',
        },
        {
          name: 'brand',
          title: `${this.$t('providers.tables.carBrand')}/${this.$t(
            'providers.tables.carModel'
          )}`,
        },
        {
          name: 'assemble_year',
          title: this.$t('providers.tables.assembleYear'),
        },
        {
          name: 'place',
          title: this.$t('providers.tables.place'),
        },
        {
          name: 'status',
          title: this.$t('orders.words.status'),
          sortField: 'status',
        },
        {
          name: 'edit',
          title: '',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getContract: 'provider/getContract',
      getOrder: 'provider/getOrder',
    }),
    filters() {
      return {
        query: {
          type: 'textfield',
          props: {
            label: false,
            placeholder: this.$t('providers.tables.plateSearchPlaceholder'),
          },
          graphqlQuery(value) {
            return {
              $or: [
                {
                  license_plate: {
                    $like: `%${value}%`,
                  },
                },
                {
                  license_plate: {
                    $like: `%${formatGrayPlateOrMercosul(value)}%`,
                  },
                },
              ],
            };
          },
        },
        status: {
          type: 'select',
          cols: 2,
          props: {
            label: this.$t('orders.words.status'),
            options: [
              {
                value: null,
                text: this.$t('orders.words.all'),
              },
              ...enumStatus,
            ],
          },
          graphqlQuery(value) {
            return !value
              ? {
                  status: enumStatus.map(item => item.value),
                }
              : { status: [value] };
          },
        },
      };
    },
  },
  mounted() {
    this.setOrder(this.$route.params.id);
  },
  methods: {
    ...mapActions({
      setOrder: 'provider/setOrder',
    }),
    $_goTo(_item = null) {
      _item = _item.data;
      this.$router.push(`/fleet/${_item.id}`);
    },
    $_goToCarEdit(id) {
      this.$router.push(`/fleet/${id}/edit`);
    },
    formatStatus,
  },
};
</script>

<style lang="sass">
.vuetable-th-slot-edit
  width: 30px
.vuetable-th-slot-status
  width: 150px
.edit-button
  padding: 0
  margin: 0
.badge
  padding: 5px 10px
  color: white
  &-ACTIVE
    background: green
  &-PENDING
    background: orange
  &-CLOSED
    background: red
</style>
