export const formatGrayPlateOrMercosul = (licensePlate) => {
  const LICENSE_PLATE_ALPHADIGIT_POSITION = 4;
  const LICENSE_PLATE_LETTERS = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];

  if (licensePlate.length <= LICENSE_PLATE_ALPHADIGIT_POSITION) return licensePlate;

  const plateSplitted = licensePlate.toUpperCase().split('');

  if (!Number.isNaN(parseFloat(plateSplitted[LICENSE_PLATE_ALPHADIGIT_POSITION]))) {
    plateSplitted[LICENSE_PLATE_ALPHADIGIT_POSITION] = LICENSE_PLATE_LETTERS[
      plateSplitted[LICENSE_PLATE_ALPHADIGIT_POSITION]];
  } else {
    plateSplitted[LICENSE_PLATE_ALPHADIGIT_POSITION] = LICENSE_PLATE_LETTERS
      .indexOf(plateSplitted[LICENSE_PLATE_ALPHADIGIT_POSITION]).toString();
  }
  return plateSplitted.join('');
};