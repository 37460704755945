var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "prodivers-list" },
    [
      _c("custom-data-table", {
        ref: "orderDataTable",
        attrs: {
          fields: _vm.fields,
          filters: _vm.filters,
          query: _vm.cars_get_list,
          "fixed-filters": { order: _vm.$route.params.order_id },
          "query-variables": { order: _vm.$route.params.order_id },
          "query-node": "carsByOrder",
        },
        on: { "vuetable:row-clicked": _vm.$_goTo },
        scopedSlots: _vm._u([
          {
            key: "license_plate",
            fn: function (props) {
              return _c("span", {}, [
                _c("strong", [_vm._v(_vm._s(props.rowData.license_plate))]),
              ])
            },
          },
          {
            key: "brand",
            fn: function (props) {
              return _c("span", {}, [
                _c("strong", [
                  _vm._v(
                    _vm._s(
                      props.rowData.brand ? props.rowData.brand.name : "--"
                    )
                  ),
                ]),
                _vm._v(
                  " / " +
                    _vm._s(
                      props.rowData.model ? props.rowData.model.name : "--"
                    ) +
                    " "
                ),
              ])
            },
          },
          {
            key: "assemble_year",
            fn: function (props) {
              return _c("span", {}, [
                _vm._v(
                  _vm._s(props.rowData.assemble_year) +
                    " / " +
                    _vm._s(props.rowData.model_year)
                ),
              ])
            },
          },
          {
            key: "place",
            fn: function (props) {
              return _c("span", {}, [
                _vm._v(
                  _vm._s(props.rowData.place ? props.rowData.place.name : "--")
                ),
              ])
            },
          },
          {
            key: "status",
            fn: function (props) {
              return _c(
                "span",
                {},
                [
                  _c(
                    "b-badge",
                    {
                      attrs: {
                        variant: _vm.formatStatus(props.rowData.status),
                      },
                    },
                    [_vm._v(_vm._s(props.rowData.status))]
                  ),
                ],
                1
              )
            },
          },
          {
            key: "edit",
            fn: function (props) {
              return _c(
                "span",
                {},
                [
                  _c(
                    "b-button",
                    {
                      attrs: { title: "Editar carro", variant: "link" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.$_goToCarEdit(props.rowData.id)
                        },
                      },
                    },
                    [_c("i", { staticClass: "fa fa-edit" })]
                  ),
                ],
                1
              )
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }