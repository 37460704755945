<template>
  <Layout>
    <order-header @newCarAdded="handleCarAdded" />
    <order-table ref="orderTable" />
  </Layout>
</template>

<script>
import Layout from '@layouts/main';
import OrderHeader from '@components/provider-order/order-header';
import OrderTable from '@components/provider-order/order-table';
export default {
  name: 'ProvidersOrders',
  components: {
    Layout,
    OrderHeader,
    OrderTable,
  },
  methods: {
    handleCarAdded() {
      this.$refs.orderTable.$refs.orderDataTable.$_refresh();
    },
  },
};
</script>
